<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right :timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs6 md6 py-8 text-left>
            <span class="kumbhBold large">Job Application for {{ applc.jobTitle.name }}</span>
          </v-flex>
          <v-flex xs6 md6 py-8 text-right pr-3>
            <!-- <v-btn dense small dark color="#001a00" depressed :ripple="false" >
             {{applc.candidateStatus}}
            </v-btn> -->

            <v-card flat>
              <v-layout wrap>
                <v-flex xs12 class="kumbhRegular">
                  <span>Candidate Status :</span>
                  <span class="kumbhBold" style="color: #001a00">
                    {{ applc.candidateStatus }}
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs9 v-if="applc" pt-6>
            <v-layout wrap justify-start text-left class="kumbhRegular">
              <v-flex xs2 py-2>
                <span><b>Applicant Name </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.name }}</span>
              </v-flex>
              <v-flex xs2 py-2>
                <span><b> Email</b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.email }}</span>
              </v-flex>
              <v-flex xs2 py-2>
                <span><b> Mobile </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.mobile }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> Date of Apply </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ DateFormatted(applc.createddate) }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> Views on Wildlife Conservation in India </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.viewOnConservation }}</span>
              </v-flex>

              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>

              <!-- <v-flex xs2 py-2>
                <span><b> Job Title </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.jobTitle.name }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> Job Category </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.jobCategory.name }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> Location </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span>{{ applc.jobTitle.location }}</span>
              </v-flex>

              <v-flex xs2 py-2>
                <span><b> content </b></span>
              </v-flex>
              <v-flex xs10 py-2>
                <span class="kumbhRegular" v-html="applc.jobTitle.content">
                </span>
              </v-flex> -->
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>

            </v-layout>
          </v-flex>
          <v-flex xs1>
            <v-divider vertical></v-divider>
          </v-flex>
          <v-flex xs2 v-if="applc">
            <v-layout wrap justify-center v-if="applc.coverLetter">
              <v-flex xs12>
                <span class="kumbhBold" style="font-size: 20px">
                  Cover Letter
                </span>
              </v-flex>
              <v-flex xs12 py-3 v-if="applc.coverLetter">
                <span
                  v-if="
                    applc.coverLetter.split('.').pop() == 'pdf' ||
                    applc.coverLetter.split('.').pop() == 'PDF' ||
                    applc.coverLetter.split('.').pop() == 'xlsx' ||
                    applc.resume.split('.').pop() == 'doc' ||
                    applc.resume.split('.').pop() == 'docx'
                  "
                >
                  <a :href="baseURL + '/i/' + applc.coverLetter">
                    <v-btn dark color="blue" small> View </v-btn></a
                  ></span
                >
                <span v-else>
                  <v-btn
                    @click="(doc = applc.coverLetter), (dialog = true)"
                    dark
                    color="blue"
                    small
                  >
                    View
                  </v-btn>
                </span>
                <!-- <v-btn dark color="blue" small>
                  <span>View</span>
                </v-btn> -->
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center v-if="applc.resume" pt-6>
              <v-flex xs12>
                <span class="kumbhBold" style="font-size: 20px"> Resume </span>
              </v-flex>
              <v-flex xs12 py-3 v-if="applc.resume">
                <span
                  v-if="
                    applc.resume.split('.').pop() == 'pdf' ||
                    applc.resume.split('.').pop() == 'PDF' ||
                    applc.resume.split('.').pop() == 'xlsx' ||
                    applc.resume.split('.').pop() == 'doc' ||
                    applc.resume.split('.').pop() == 'docx'
                  "
                >
                  <a :href="baseURL + '/i/' + applc.resume">
                    <v-btn dark color="blue" small> View </v-btn></a
                  ></span
                >
                <span v-else>
                  <v-btn
                    @click="(doc = applc.resume), (dialog = true)"
                    dark
                    color="blue"
                    small
                  >
                    View
                  </v-btn>
                </span>
              </v-flex>
            </v-layout>
          
            <v-dialog
              persistent
              v-model="addEmployeeDialog"
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="applc.candidateStatus != 'Selected'"
                  small
                  style="font-family: kumbhSemibold"
                  class="mr-2"
                  dense
                  dark
                  block
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                >
                  <span v-if="applc.candidateStatus == 'Pending'">
                    Interested
                  </span>
                  <span v-if="applc.candidateStatus == 'Interested'">
                    Shortlist
                  </span>
                  <span v-if="applc.candidateStatus == 'Shortlisted'">
                    Select Candidate
                  </span>
                </v-btn>
             
               
               
          
              </template>
              <v-card>
                <v-card-title>
                  <template v-if="applc.candidateStatus == 'Shortlisted'">
                    Are you sure you want select this candidate?
                  </template>
                  <template v-if="applc.candidateStatus == 'Interested'">
                    Do you want to shortlist this candidate?
                  </template>
                  <template v-if="applc.candidateStatus == 'Pending'">
                    Do you want to move this candidate to interested list?
                  </template>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addEmployeeDialog = false"
                    >No</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addAsEmployee"
                    v-if="applc.candidateStatus == 'Shortlisted'"
                    >Yes</v-btn
                  >

                  <v-btn
                    color="blue darken-1"
                    text
                    @click="ChangeEmpStatus('Shortlisted')"
                    v-if="applc.candidateStatus == 'Interested'"
                    >Yes</v-btn
                  >

                  <v-btn
                    color="blue darken-1"
                    text
                    @click="ChangeEmpStatus('Interested')"
                    v-if="applc.candidateStatus == 'Pending'"
                    >Yes</v-btn
                  >

                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-flex xs1 pt-1>
              <v-btn
              v-if="applc.candidateStatus != 'Selected'"
              small
              style="font-family: kumbhSemibold"
              class="mr-2"
              dense
              dark
              block
              v-on="on"
              v-bind="attrs"
              color="green"
              pt2
              pb2
            >
              <span v-if="applc.candidateStatus == 'Pending'">
                Move to Candidate Pool
              </span>
              </v-btn>
            </v-flex>
          </v-flex>
  
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="dialog" width="70%">
      <v-card>
        <v-toolbar height="30px" dense dark :color="appColor">
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(doc = null), (dialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>

        <!-- <v-card-text v-if="doc.split('.').pop() == 'pdf'">
          <iframe
            v-if="doc"
            :src="baseURL + '/' + doc"
            height="700px"
            width="900px"
            contain
          >
          </iframe>
        </v-card-text> -->
        <v-card-text v-if="doc">
          <v-img :src="baseURL + '/i/' + doc"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            small
            color="red"
            text
            @click="(dialog = false), (doc = null)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      addEmployeeDialog: false,
      doc: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      location: "",
      jobCategory: null,
      categoryList: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      applc: null,
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    ChangeEmpStatus(value) {
      axios({
        url: "/job/application/change/candidateStatus",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          candidateStatus: value,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.showsnackbar = true;
          this.msg = response.data.msg;
          this.addEmployeeDialog = false;
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    DateFormatted(params) {
      // moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
      // return params ? moment(params).format("DD-MMMM-Y <br> hh:mm A") : "";

      params = moment(params).subtract(5, "h").subtract(30, "m");
      return params ? moment(params).format("DD-MMMM-Y") : "";
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/job/application/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.applc = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addAsEmployee() {
      axios({
        url: "/accept/resume",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          name:"testName1",
          email:"mitilef687@abevk.com",
          phonenumber:"1234567891",
          positionid:"63ef49f34345bd37be2b1a26",
          employeeId:"100011",
          dateofjoining:"2024-10-01"
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.showsnackbar = true;
          this.msg = response.data.msg;
          this.$router.go(-1);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
